import React from 'react'
import Img from 'gatsby-image'

// Components
import ScrollTo from '../../../components/ScrollTo'

// PageQuery
import usePageQuery from '../../pageQuery'

// Styles
import { SectionWrapper } from './style'

const Processo = () => {
  const data = usePageQuery()

  return (
    <SectionWrapper className='py-5'>
      <div className='container'>
        <div className='row d-flex justify-content-between'>
          <div className='col-12 col-md-6 col-lg-5'>
            <Img fluid={data.processosEstrategicosImagem.fluid} alt='Pessoa escrevendo em um carderno' />
          </div>
          <div className='col-12 col-md-6 mt-4 mt-md-0'>
            <h3 className='f-sora fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-400'>Processos estratégicos. Portfólio completo.</h3>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 fw-400'>Processos de decisão bem definidos, tecnologia e a experiência dos nossos gestores são o que orientam nossa busca estratégica pelas melhores soluções.</p>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 fw-400'>Temos como princípio oferecer produtos competitivos e acessíveis a todos os investidores e hoje trabalhamos com uma gama completa de soluções por meio da gestão de fundos próprios, fundos de fundos e Wealth Management.</p>
            <ScrollTo
              to='#fundosSection'
              className='btn fs-12 lh-14 text-white fw-700 rounded-5 btn'
              title='Conheça os fundos'
              section='dobra_03'
              sectionName='Processos estratégicos. Portfólio completo'
              elementName='Conheça os fundos'
            >
              Conheça os fundos
            </ScrollTo>
          </div>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default Processo
