import styled from 'styled-components'
import { orange } from '../../../styles/colors'

// styles
import breakpoints from '../../../styles/breakpoints'

export const SectionWrapper = styled.section`
  display: flex;
  align-items: center;
  background: #fff;
  color: #161616;

  @media (min-width: ${breakpoints.md}) {
    background: #172740;
    color: #ffffff;
  }

  a {
    background-color: ${orange.base};
    outline: none;
    height: 40px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: ${breakpoints.md}) {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }

    &:hover {
      background: ${orange.base};
      opacity: 0.9;
    }
  }
`
