import React, { MouseEvent } from 'react'
import useDataLayer from '../../hooks/useDataLayer/dataLayerBody'

interface IScrollTo {
  to: string;
  children: React.ReactNode;
  title: string;
  className: string;
  section?: string;
  sectionName?: string;
  elementName?: string;
}

const ScrollTo: React.FC<IScrollTo> = ({ to, children, title, className, section, sectionName, elementName }: IScrollTo): React.ReactElement => {
  const [ sendDatalayerEvent ] = useDataLayer()

  function scrollTo (evt: React.MouseEvent) {
    evt.preventDefault()
    const target = evt.target as HTMLAnchorElement
    const topElement = document.getElementById(target.hash.replace('#', ''))
    if (!topElement) { return }
    const offsetTopElem = topElement.getBoundingClientRect()
    if (typeof window !== 'undefined') {
      const top = offsetTopElem.top + window.scrollY - 190
      window.scroll({
        top,
        behavior: 'smooth',
      })
    }
  }

  return (
    <a
      className={className}
      href={to}
      onClick={(evt: MouseEvent<HTMLAnchorElement>) => {
        scrollTo(evt)
        sendDatalayerEvent({
          section: section,
          section_name: sectionName,
          element_name: elementName,
          element_action: 'click scroll',
          redirect_url: window.location.href + to,
        })
      }}
      title={title}
    >
      {children}
    </a>
  )
}

export default ScrollTo
